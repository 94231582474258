import Multiselect, { DropdownData } from '@/shared/components/Multiselect'
import { Component, For, Match, Show, Switch } from 'solid-js'
import { CareerStore } from '../career-list'
import { CareerListQuery } from '@/shared/generated/graphql'
import { FILTER_OPTION_NAMES, FilterTypes } from '../shared/filter-configuration'
import Chips from '@/shared/components/Chips'

type Props = {
	store: CareerStore
	onFilterChange: (path: string, items: DropdownData[]) => void
	onRangeFilterChange: (path: string, value: DropdownData[]) => void
	onFilterOptionDisabled: (path: string, value: string) => void
	mode?: 'full' | 'list' | 'search' | 'list-evu'
}

type DiscreteFacet = Extract<CareerListQuery['postings']['facets'][number], { __typename: 'DiscreteFacet' }>

const Filters: Component<Props> = props => {
	const normalFilters = () => props.store.filters?.filter(x => x.path !== 'source.name_short')
	const normalFacets = () =>
		props.store.facets?.filter((x): x is DiscreteFacet => x.path !== 'source.name_short' && x.__typename! === 'DiscreteFacet')

	const sourceFacet = () =>
		props?.store?.facets?.find((x): x is DiscreteFacet => x.path === 'source.name_short' && x.__typename === 'DiscreteFacet')

	/* const startingDateOptions = (): DropdownData[] => {
		const ret: DropdownData[] = []
		ret.push({
			name: FILTER_OPTION_NAMES['starting_date']['sofort'] ?? 'Sofort',
			value: 'sofort',
			selected: props.store.starting_date.toLowerCase() === 'sofort',
			disabled: false,
		})
		ret.push({
			name: FILTER_OPTION_NAMES['starting_date']['3monate'] ?? '< 3 Monaten',
			value: '3monate',
			selected: props.store.starting_date.toLowerCase() === '3monate',
			disabled: false,
		})
		ret.push({
			name: FILTER_OPTION_NAMES['starting_date']['3-6monate'] ?? 'zwischen 3-6 Monaten',
			value: '3-6monate',
			selected: props.store.starting_date.toLowerCase() === '3-6monate',
			disabled: false,
		})
		ret.push({
			name: FILTER_OPTION_NAMES['starting_date']['6monate'] ?? '> 6 Monaten',
			value: '6monate',
			selected: props.store.starting_date.toLowerCase() === '6monate',
			disabled: false,
		})
		return ret
	} */

	const onFilterChange = (path: string, items: DropdownData[]) => {
		props.onFilterChange(path, items)
	}

	return (
		<>
			<div class="selects">
				<Switch>
					<Match when={props.store.postingsPagination.isLoading && normalFacets().length === 0}>
						<For each={normalFilters()}>
							{_ => <div class="h-[40px] w-full animate-pulse rounded-md bg-lightergrey md:h-10 xl:h-12" />}
						</For>
					</Match>
					<Match when={true}>
						<Show when={props.mode == 'list'}>
							<Multiselect
								data={normalFacets()[4]
									.options.map(x => ({
										name:
											(FILTER_OPTION_NAMES[normalFacets()[4]?.path as FilterTypes] as any)?.[x.value] ??
											x.value,
										disabled: x.disabled,
										selected: x.selected,
										value: x.value,
										group: x.disabled ? ' ' : undefined,
									}))
									.filter(x => x.name !== '')}
								features={props.store.locationsInRange ?? []}
								label={{
									button: normalFacets()[4]?.label,
									search: 'Suche',
									noSelection: 'Keine Daten gefunden',
									feature: 'Standorte im gesuchten Umkreis',
									featureFollowUp: 'Weitere Standorte',
								}}
								settings={{
									searchField: true,
									multiSelect: true,
									showSelected: true,
									closeOnSingleSelect: true,
									showClear: true,
									noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
								}}
								styleClasses={{
									dropdownListItemGroupLabels: 'm-0',
								}}
								selectedItems={items => onFilterChange(normalFacets()[4].path, items)}
							/>
						</Show>
						<Show when={props.mode !== 'list'}>
							<Multiselect
								data={normalFacets()[0]
									.options.map(x => ({
										name:
											(FILTER_OPTION_NAMES[normalFacets()[0]?.path as FilterTypes] as any)?.[x.value] ??
											x.value,
										disabled: x.disabled,
										selected: x.selected,
										value: x.value,
									}))
									.filter(x => x.name !== '')}
								label={{
									button: normalFacets()[0]?.label,
									search: 'Suche',
									noSelection: 'Keine Daten gefunden',
								}}
								settings={{
									searchField: true,
									multiSelect: true,
									showSelected: true,
									closeOnSingleSelect: true,
									showClear: true,
									noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
								}}
								selectedItems={items => onFilterChange(normalFacets()[0].path, items)}
							/>
						</Show>
						<Multiselect
							data={normalFacets()[1]
								.options.map(x => ({
									name:
										(FILTER_OPTION_NAMES[normalFacets()[1]?.path as FilterTypes] as any)?.[x.value] ??
										x.value,
									disabled: x.disabled,
									selected: x.selected,
									value: x.value,
								}))
								.filter(x => x.name !== '')}
							label={{
								button: normalFacets()[1]?.label,
								search: 'Suche',
								noSelection: 'Keine Daten gefunden',
							}}
							settings={{
								searchField: false,
								multiSelect: true,
								showSelected: true,
								closeOnSingleSelect: true,
								showClear: true,
								sort: true,
								noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
							}}
							selectedItems={items => onFilterChange(normalFacets()[1].path, items)}
						/>
						<Multiselect
							data={normalFacets()[2]
								.options.map(x => ({
									name:
										(FILTER_OPTION_NAMES[normalFacets()[2]?.path as FilterTypes] as any)?.[x.value] ??
										x.value,
									disabled: x.disabled,
									selected: x.selected,
									value: x.value,
								}))
								.filter(x => x.name !== '')}
							label={{
								button: normalFacets()[2]?.label,
								search: 'Suche',
								noSelection: 'Keine Daten gefunden',
							}}
							settings={{
								searchField: false,
								multiSelect: true,
								showSelected: true,
								closeOnSingleSelect: true,
								showClear: true,
								noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
							}}
							selectedItems={items => onFilterChange(normalFacets()[2].path, items)}
						/>
						<Multiselect
							data={normalFacets()[3]
								.options.map(x => ({
									name:
										(FILTER_OPTION_NAMES[normalFacets()[3]?.path as FilterTypes] as any)?.[x.value] ??
										x.value,
									disabled: x.disabled,
									selected: x.selected,
									value: x.value,
								}))
								.filter(x => x.name !== '')}
							label={{
								button: normalFacets()[3]?.label,
								search: 'Suche',
								noSelection: 'Keine Daten gefunden',
							}}
							settings={{
								searchField: false,
								multiSelect: true,
								showSelected: true,
								closeOnSingleSelect: true,
								showClear: true,
								noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
							}}
							selectedItems={items => onFilterChange(normalFacets()[3].path, items)}
						/>
						<Show when={props.mode !== 'list'}>
							<Multiselect
								data={normalFacets()[4]
									.options.map(x => ({
										name:
											(FILTER_OPTION_NAMES[normalFacets()[4]?.path as FilterTypes] as any)?.[x.value] ??
											x.value,
										disabled: x.disabled,
										selected: x.selected,
										value: x.value,
										group: x.disabled ? ' ' : undefined,
									}))
									.filter(x => x.name !== '')}
								features={props.store.locationsInRange ?? []}
								label={{
									button: normalFacets()[4]?.label,
									search: 'Suche',
									noSelection: 'Keine Daten gefunden',
									feature: 'Standorte im gesuchten Umkreis',
									featureFollowUp: 'Weitere Standorte',
								}}
								settings={{
									searchField: true,
									multiSelect: true,
									showSelected: true,
									closeOnSingleSelect: true,
									showClear: true,
									noOptionsLabel: 'Keine Auswahlmöglichkeit für deine Suchanfrage.',
								}}
								styleClasses={{
									dropdownListItemGroupLabels: 'm-0',
								}}
								selectedItems={items => onFilterChange(normalFacets()[4].path, items)}
							/>
						</Show>
					</Match>
				</Switch>
			</div>

			<Show when={props.mode !== 'list-evu'}>
				<h3 class="filter-subtitle text-bwGrey">Arbeitgeber</h3>
				<Switch>
					<Match
						when={
							props.store.postingsPagination.isLoading &&
							props.store.facets.find(facet => facet.path === 'source.name_short') === undefined
						}>
						<div class="flex gap-3">
							<For each={Array.from(Array(3).keys())}>
								{_ => <div class="h-[50px] w-32 animate-pulse rounded-md bg-lightergrey md:h-10 xl:h-12" />}
							</For>
						</div>
					</Match>
					<Match when={true}>
						<Chips
							data={
								sourceFacet()?.options.map(x => ({
									name: x.value,
									value: x.value,
									selected:
										props.store.filters
											.find(filter => filter.path === 'source.name_short')
											?.options?.some(option => option === x.value) ?? false,
									disabled: x.disabled,
								})) ?? []
							}
							onSelect={items => onFilterChange('source.name_short', items)}
						/>
					</Match>
				</Switch>
			</Show>

			{/* <Show when={props.store.closeLocations.length > 0}>
						<h3 class="filter-subtitle">Standorte</h3>
						<Switch>
							<Match when={props.store.postingsPagination.isLoading && props.store.closeLocations.length === 0}>
								<div class="flex gap-3">
									<For each={Array.from(Array(3).keys())}>
										{_ => (
											<div class="h-[50px] w-32 animate-pulse rounded-md bg-lightergrey md:h-10 xl:h-12" />
										)}
									</For>
								</div>
							</Match>
							<Match when={true}>
								<Chips
									data={
										props.store.closeLocations.map(x => ({
											name: x,
											value: x,
											selected: false,
											disabled: false,
										})) ?? []
									}
									onSelect={items =>
										onFilterChange('locations.name', [
											...selectedLocationFilterOptions().map(x => ({
												name: x,
												value: x,
												disabled: false,
												selected: true,
											})),
											...items,
										])
									}
								/>
							</Match>
						</Switch>
					</Show> */}
		</>
	)
}

export default Filters
