import { Component, For, createEffect, createSignal, on, Show } from 'solid-js'
import { Combobox, ComboboxInputValueChangeDetails, ComboboxValueChangeDetails } from '@ark-ui/solid'
import { Portal } from 'solid-js/web'
import LoadingSpinner from '@/shared/components/LoadingSpinner'
import ErrorTooltip from '@/shared/components/ErrorTooltip'

type Props = {
	items: SearchableItem[]
	value?: string
	inputLabel?: string
	ariaLabel?: string
	placeholderLabel?: string
	staticLabel?: string
	onSearch?: (query: string) => void
	onSelect?: (value: string, label: string) => void
	loading?: boolean
	openOnClick?: boolean
	error?: string
	id?: string
}

interface SearchableItem {
	name: string
	id: string
}

interface ComboboxItem {
	label: string
	value: string
}

const ComboSelect: Component<Props> = props => {
	// eslint-disable-next-line solid/reactivity
	const [searchTerm, setSearchTerm] = createSignal(props.value ?? '')
	const [items, setItems] = createSignal<ComboboxItem[]>([], { equals: false })

	createEffect(() => {
		setItems(props.items.map(item => ({ label: item.name, value: item.id }) as ComboboxItem))
	})

	createEffect(
		on(
			() => props.value,
			() => {
				if (props.value !== searchTerm()) {
					setSearchTerm(props.value ?? '')
					props.onSearch?.(searchTerm())
				}
			}
		)
	)

	const onSelect = (item: ComboboxValueChangeDetails<ComboboxItem>) => {
		if (!item) {
			return
		}
		props.onSelect?.(item?.items[0].value ?? '', item.items[0].label)
	}

	return (
		<Combobox.Root
			items={items()}
			onInputValueChange={(value: ComboboxInputValueChangeDetails) => {
				setSearchTerm(value.inputValue ?? '')
				props.onSearch?.(value.inputValue ?? '')
			}}
			onValueChange={detail => onSelect(detail)}
			openOnClick={props.openOnClick}
			translations={{ triggerLabel: 'Suche', clearTriggerLabel: 'Löschen' }}
			class="w-full"
			inputValue={searchTerm() ?? ''}>
			<Show when={props?.inputLabel}>
				<Combobox.Label class="">{props?.inputLabel ?? ''}</Combobox.Label>
			</Show>
			<Combobox.Control>
				<div class="relative">
					<ErrorTooltip text="Bitte Haltestelle wählen" arrow={true} error={props.error}>
						<Combobox.Input
							classList={{
								error: !!props.error,
							}}
							placeholder={props?.placeholderLabel ?? ''}
							id={props?.id ?? ''}
							class="w-full !pb-[4px] !pl-[3rem] !pr-[4px] !pt-[5px]"
							aria-label={props?.ariaLabel ?? ''}
						/>
					</ErrorTooltip>
					<Show when={props.staticLabel}>
						<label class="static-label" for={props?.id ?? ''}>
							{props.staticLabel}
						</label>
					</Show>
					<Show when={props.loading !== undefined}>
						<div class="absolute right-2 top-1/2 -translate-y-1/2">
							<LoadingSpinner class="h-4 w-auto" show={props.loading!} />
						</div>
					</Show>
				</div>
			</Combobox.Control>
			<Portal>
				<Combobox.Positioner>
					<Combobox.Content
						class="z-20 max-h-[15rem] overflow-y-auto rounded-md bg-white shadow-2xl"
						aria-labelledby="">
						<For each={items()}>
							{(_item, index) => (
								<Combobox.Item
									item={_item}
									classList={{ 'border-b border-b-gray-200': index() !== items().length - 1 }}
									class="text-md cursor-pointer px-3 py-2 hover:bg-gray-100">
									<Combobox.ItemText>{_item?.label}</Combobox.ItemText>
								</Combobox.Item>
							)}
						</For>
					</Combobox.Content>
				</Combobox.Positioner>
			</Portal>
		</Combobox.Root>
	)
}

export default ComboSelect
