export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	/** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
	DateTime: { input: any; output: any }
}

/** The Article type */
export type Article = {
	__typename?: 'Article'
	/** Abstract */
	abstract?: Maybe<Scalars['String']['output']>
	/** Api Version */
	apiVersion?: Maybe<Scalars['Int']['output']>
	/** Author */
	author?: Maybe<Scalars['String']['output']>
	/** Copyright */
	copyright?: Maybe<Scalars['String']['output']>
	/** Created At */
	createdAt?: Maybe<Scalars['DateTime']['output']>
	/** Current Information */
	currentInformation?: Maybe<Scalars['String']['output']>
	/** Description */
	description?: Maybe<Scalars['String']['output']>
	/** Highlight */
	highlight?: Maybe<Scalars['Boolean']['output']>
	/** Id */
	id?: Maybe<Scalars['String']['output']>
	/** Invisible */
	invisible?: Maybe<Scalars['Boolean']['output']>
	/** Latitude */
	latitude?: Maybe<Scalars['Float']['output']>
	/** License */
	license?: Maybe<Scalars['String']['output']>
	/** Locale */
	locale?: Maybe<Scalars['String']['output']>
	/** Longitude */
	longitude?: Maybe<Scalars['Float']['output']>
	/** Name */
	name?: Maybe<Scalars['String']['output']>
	/** Nearby Articles */
	nearbyArticles: Array<Article>
	/** Page id */
	pid: Scalars['Int']['output']
	/** Primary Category */
	primaryCategory?: Maybe<ToubizApiCategory>
	/** Source Information Link */
	sourceInformationLink?: Maybe<Scalars['String']['output']>
	/** Tip Famous */
	tipFamous?: Maybe<Scalars['Boolean']['output']>
	/** Tip Highlight */
	tipHighlight?: Maybe<Scalars['Boolean']['output']>
	/** Tip Idyllic */
	tipIdyllic?: Maybe<Scalars['Boolean']['output']>
	/** Tip Natural */
	tipNatural?: Maybe<Scalars['Boolean']['output']>
	/** Tip Off The Track */
	tipOffTheTrack?: Maybe<Scalars['Boolean']['output']>
	/** Tip Only Here */
	tipOnlyHere?: Maybe<Scalars['Boolean']['output']>
	/** Tip Popular */
	tipPopular?: Maybe<Scalars['Boolean']['output']>
	/** Tip Typical For Region */
	tipTypicalForRegion?: Maybe<Scalars['Boolean']['output']>
	/** Type */
	type?: Maybe<Scalars['String']['output']>
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
	/** Updated At */
	updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ArticleEdge = {
	__typename?: 'ArticleEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: Article
}

export type ArticlesConnection = {
	__typename?: 'ArticlesConnection'
	/** A list of the edges. */
	edges: Array<ArticleEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<Article>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

export enum CareerLevel {
	/** Quereinsteiger */
	Careerchanger = 'careerchanger',
	/** Mit Berufserfahrung */
	Experienced = 'experienced',
	/** Personalverantwortung */
	PersonnelResponsibility = 'personnel_responsibility',
	/** Ohne Berufserfahrung / Berufseinstieg */
	Student = 'student',
}

export type CategoriesConnection = {
	__typename?: 'CategoriesConnection'
	/** A list of the edges. */
	edges: Array<CategoryEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<Category>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

/** The Category type */
export type Category = {
	__typename?: 'Category'
	/** Page id */
	pid: Scalars['Int']['output']
	/** Title */
	title: Scalars['String']['output']
	/** Toubiz API-Kategorien */
	toubizApiCategories: Array<ToubizApiCategory>
	/** Toubiz Subkategorie */
	toubizChildCategories: Array<Category>
	/** Toubiz Hauptkategorie */
	toubizParentCategories: Array<Category>
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
}

export type CategoryEdge = {
	__typename?: 'CategoryEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: Category
}

export type CategorySorting = {
	/** Sort by field */
	field: CategorySortingField
	/** Sort order */
	order: SortingOrder
}

export enum CategorySortingField {
	/** sorting */
	Sorting = 'sorting',
}

export type DiscreteFacet = {
	__typename?: 'DiscreteFacet'
	/** The filter label */
	label: Scalars['String']['output']
	/** The result count for this filter option */
	options: Array<FilterOption>
	/** The filter path */
	path: Scalars['String']['output']
}

export type DiscreteFilterInput = {
	/** Selected filter options */
	options?: InputMaybe<Array<Scalars['String']['input']>>
	/** The filter path */
	path: Scalars['String']['input']
}

export enum EmploymentForm {
	/** Ausbildung */
	Apprenticeship = 'apprenticeship',
	/** Duales Studium */
	DualStudies = 'dual_studies',
	/** Praktikum */
	Internship = 'internship',
	/** Feste Anstellung */
	PermanentEmployment = 'permanent_employment',
	/** Befristet */
	TemporaryEmployment = 'temporary_employment',
	/** Werkstudent:in */
	WorkingStudent = 'working_student',
}

export enum EmploymentType {
	/** Full-Time */
	FullTime = 'full_time',
	/** Part-Time */
	PartTime = 'part_time',
}

export type Facets = DiscreteFacet | RangeFacet

export type File = {
	__typename?: 'File'
	/** Returns the alternative text for this file */
	alternative?: Maybe<Scalars['String']['output']>
	copyright?: Maybe<Scalars['String']['output']>
	/** Derivative of the file. Returns the absolute URL to the processed file. */
	derivative?: Maybe<Scalars['String']['output']>
	/** File extension */
	extension: Scalars['String']['output']
	/** Filename with extension */
	fileName: Scalars['String']['output']
	/** Filesize in Bytes */
	fileSize: Scalars['Int']['output']
	/** Returns the link for this file reference. This is the link that can be edited in the background. Not the link to the file. */
	link?: Maybe<Scalars['String']['output']>
	/** Absolute URL to file */
	url: Scalars['String']['output']
}

export type FileDerivativeArgs = {
	crop?: InputMaybe<Scalars['String']['input']>
	fileExtension?: InputMaybe<FileExtensions>
	height?: InputMaybe<Scalars['Int']['input']>
	maxHeight?: InputMaybe<Scalars['Int']['input']>
	maxWidth?: InputMaybe<Scalars['Int']['input']>
	minHeight?: InputMaybe<Scalars['Int']['input']>
	minWidth?: InputMaybe<Scalars['Int']['input']>
	width?: InputMaybe<Scalars['Int']['input']>
}

export enum FileExtensions {
	Ai = 'ai',
	Bmp = 'bmp',
	Gif = 'gif',
	Jpeg = 'jpeg',
	Jpg = 'jpg',
	Pcx = 'pcx',
	Pdf = 'pdf',
	Png = 'png',
	Svg = 'svg',
	Tga = 'tga',
	Tif = 'tif',
	Tiff = 'tiff',
}

export type FilterCollectionInput = {
	/** Discrete filters */
	discreteFilters?: InputMaybe<Array<DiscreteFilterInput>>
	/** Range filters */
	rangeFilters?: InputMaybe<Array<RangeFilterInput>>
}

export type FilterOption = {
	__typename?: 'FilterOption'
	/** Whether this filter option is disabled, because of other filters. */
	disabled: Scalars['Boolean']['output']
	/** The result count for this filter option */
	resultCount: Scalars['Int']['output']
	/** Whether this filter option was selected through a filter argument. */
	selected: Scalars['Boolean']['output']
	/** The filter value */
	value: Scalars['String']['output']
}

/** The GraphQLFilter type */
export type GraphQlFilter = {
	__typename?: 'GraphQLFilter'
	/** Page id */
	pid: Scalars['Int']['output']
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
}

export type GraphQlFilterEdge = {
	__typename?: 'GraphQLFilterEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: GraphQlFilter
}

export type GraphQlFiltersConnection = {
	__typename?: 'GraphQLFiltersConnection'
	/** A list of the edges. */
	edges: Array<GraphQlFilterEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<GraphQlFilter>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

/** The Location type */
export type Location = {
	__typename?: 'Location'
	/** Address City */
	addressCity?: Maybe<Scalars['String']['output']>
	/** Name */
	name?: Maybe<Scalars['String']['output']>
	/** Page id */
	pid: Scalars['Int']['output']
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
}

/** The Page type */
export type Page = {
	__typename?: 'Page'
	/** Categories */
	categories: Array<Category>
	/**
	 * Alternative Small Teaser Image
	 *  - Allowed file types: gif,jpg,jpeg,tif,tiff,bmp,pcx,tga,png,pdf,ai,svg
	 */
	magazineAlternativeSmallTeaserImage?: Maybe<File>
	/** Button text */
	magazineCtaText: Scalars['String']['output']
	/** Show Frame */
	magazineTeaserFrame?: Maybe<Scalars['Boolean']['output']>
	/**
	 * Teaser Image
	 *  - Allowed file types: gif,jpg,jpeg,tif,tiff,bmp,pcx,tga,png,pdf,ai,svg
	 *  - Available crop variants: Horizontal, Vertical
	 */
	magazineTeaserImage: File
	/** Page id */
	pid: Scalars['Int']['output']
	/** Pagetitle */
	title: Scalars['String']['output']
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
	/** The url to the page */
	url: Scalars['String']['output']
}

export type PageEdge = {
	__typename?: 'PageEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: Page
}

export type PageInfo = {
	__typename?: 'PageInfo'
	/** The last cursor, of the objects. This can be used to access the next page. */
	endCursor: Scalars['String']['output']
	/** Whether there are more items available */
	hasNextPage: Scalars['Boolean']['output']
}

export type PageSorting = {
	/** Sort by field */
	field: PageSortingField
	/** Sort order */
	order: SortingOrder
}

export enum PageSortingField {
	/** crdate */
	Crdate = 'crdate',
	/** sorting */
	Sorting = 'sorting',
	/** uid */
	Uid = 'uid',
}

export type PagesConnection = {
	__typename?: 'PagesConnection'
	/** A list of the edges. */
	edges: Array<PageEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<Page>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

/** The Posting type */
export type Posting = {
	__typename?: 'Posting'
	/** Career Level */
	careerLevel: Array<CareerLevel>
	/** Categories */
	categories: Array<Category>
	/** Date Posted */
	datePosted: Scalars['DateTime']['output']
	/** The distance to the search location */
	distance?: Maybe<Scalars['Float']['output']>
	/** Employment Form */
	employmentForm: Array<EmploymentForm>
	/** Employment Type */
	employmentType: Array<EmploymentType>
	/** Locations */
	locations: Array<Location>
	/** Page id */
	pid: Scalars['Int']['output']
	/** Source */
	source: Source
	/** Starting Date */
	startingDate?: Maybe<Scalars['DateTime']['output']>
	/** Title */
	title: Scalars['String']['output']
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
	/** The url to the detail page */
	url: Scalars['String']['output']
}

export type PostingEdge = {
	__typename?: 'PostingEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: Posting
}

export type PostingSorting = {
	/** Sort by field */
	field: PostingSortingField
	/** Sort order */
	order: SortingOrder
}

export enum PostingSortingField {
	/** crdate */
	Crdate = 'crdate',
	/** distance */
	Distance = 'distance',
	/** relevance */
	Relevance = 'relevance',
	/** sorting */
	Sorting = 'sorting',
	/** starting_date */
	StartingDate = 'starting_date',
}

export type PostingsConnection = {
	__typename?: 'PostingsConnection'
	/** A list of the edges. */
	edges: Array<PostingEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<Posting>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

export type Query = {
	__typename?: 'Query'
	article?: Maybe<Article>
	articles: ArticlesConnection
	categories: CategoriesConnection
	category?: Maybe<Category>
	/** Returns all locations in a set radius */
	closeLocations: Array<Location>
	filter?: Maybe<GraphQlFilter>
	filters: GraphQlFiltersConnection
	/** Finds locations within a given radius */
	findLocations: Array<Location>
	page?: Maybe<Page>
	pages: PagesConnection
	posting?: Maybe<Posting>
	postings: PostingsConnection
	/** Postings where n-1 filter options fit the filter query */
	similarPostings: PostingsConnection
	/** Suggests locations by name or Postal Code */
	suggestLocations: Array<Location>
	/** Suggests posting titles by title and content */
	suggestPostings: Array<Scalars['String']['output']>
	toubizapicategories: ToubizApiCategoriesConnection
	toubizapicategory?: Maybe<ToubizApiCategory>
}

export type QueryArticleArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type QueryArticlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type QueryCategoriesArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sorting?: InputMaybe<Array<CategorySorting>>
}

export type QueryCategoryArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type QueryCloseLocationsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	locations: Array<Scalars['String']['input']>
	radius?: InputMaybe<Scalars['Int']['input']>
}

export type QueryFilterArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type QueryFiltersArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type QueryFindLocationsArgs = {
	query: Scalars['String']['input']
	radius?: InputMaybe<Scalars['Int']['input']>
}

export type QueryPageArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type QueryPagesArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	sorting?: InputMaybe<Array<PageSorting>>
}

export type QueryPostingArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type QueryPostingsArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
	plzSearchQuery?: InputMaybe<Scalars['String']['input']>
	plzSearchRange?: InputMaybe<Scalars['Int']['input']>
	searchQuery?: InputMaybe<Scalars['String']['input']>
	sorting?: InputMaybe<Array<PostingSorting>>
}

export type QuerySimilarPostingsArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySuggestLocationsArgs = {
	query: Scalars['String']['input']
}

export type QuerySuggestPostingsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	searchQuery: Scalars['String']['input']
}

export type QueryToubizapicategoriesArgs = {
	after?: InputMaybe<Scalars['String']['input']>
	filters?: InputMaybe<FilterCollectionInput>
	first?: InputMaybe<Scalars['Int']['input']>
	language?: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	pageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type QueryToubizapicategoryArgs = {
	language?: Scalars['Int']['input']
	uid: Scalars['Int']['input']
}

export type Range = {
	__typename?: 'Range'
	max: Scalars['Int']['output']
	min: Scalars['Int']['output']
}

export type RangeFacet = {
	__typename?: 'RangeFacet'
	/** The filter label */
	label: Scalars['String']['output']
	/** The filters path */
	path: Scalars['String']['output']
	range: Range
	resultCount: Scalars['Int']['output']
	unit: Scalars['String']['output']
}

export type RangeFilterInput = {
	/** The filter path */
	path: Scalars['String']['input']
	range?: InputMaybe<RangeInput>
}

/** Inclusive range of numbers */
export type RangeInput = {
	max?: InputMaybe<Scalars['Int']['input']>
	min?: InputMaybe<Scalars['Int']['input']>
}

export enum SortingOrder {
	Ascending = 'Ascending',
	Descending = 'Descending',
}

/** The Source type */
export type Source = {
	__typename?: 'Source'
	/**
	 * Logo
	 *  - Allowed file types: gif,jpg,jpeg,tif,tiff,bmp,pcx,tga,png,pdf,ai,svg
	 */
	logo?: Maybe<File>
	/** Name */
	name: Scalars['String']['output']
	/** Short Name */
	nameShort: Scalars['String']['output']
	/** Page id */
	pid: Scalars['Int']['output']
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
}

export type ToubizApiCategoriesConnection = {
	__typename?: 'ToubizAPICategoriesConnection'
	/** A list of the edges. */
	edges: Array<ToubizApiCategoryEdge>
	/** This field Contains Range Facets and discrete Facets */
	facets: Array<Facets>
	/** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } }" version should be used instead. */
	items: Array<ToubizApiCategory>
	/** Information to navigate the pagination. */
	pageInfo: PageInfo
	/** Total count of object in this connection. */
	totalCount: Scalars['Int']['output']
}

/** The ToubizAPICategory type */
export type ToubizApiCategory = {
	__typename?: 'ToubizAPICategory'
	/** Id */
	id?: Maybe<Scalars['String']['output']>
	/** Name */
	name?: Maybe<Scalars['String']['output']>
	/** Name With Parents */
	nameWithParents?: Maybe<Scalars['String']['output']>
	/** Page id */
	pid: Scalars['Int']['output']
	/** Toubiz Kategorien */
	toubizCategories: Array<Category>
	/** Type */
	type?: Maybe<Scalars['String']['output']>
	/** Unique identifier in table */
	uid: Scalars['Int']['output']
}

export type ToubizApiCategoryEdge = {
	__typename?: 'ToubizAPICategoryEdge'
	/** A cursor for pagination */
	cursor: Scalars['String']['output']
	/** Contains the concrete object */
	node: ToubizApiCategory
}

export type ArticlesQueryVariables = Exact<{
	uids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
}>

export type ArticlesQuery = {
	__typename?: 'Query'
	articles: {
		__typename?: 'ArticlesConnection'
		items: Array<{
			__typename?: 'Article'
			uid: number
			name?: string | null
			latitude?: number | null
			longitude?: number | null
		}>
	}
}

export type CategoriesQueryVariables = Exact<{
	pageId?: InputMaybe<Scalars['Int']['input']>
}>

export type CategoriesQuery = {
	__typename?: 'Query'
	categories: { __typename?: 'CategoriesConnection'; items: Array<{ __typename?: 'Category'; uid: number; title: string }> }
}

export type CareerListQueryVariables = Exact<{
	first: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	discreteFilters: Array<DiscreteFilterInput> | DiscreteFilterInput
	closeLocations: Array<Scalars['String']['input']> | Scalars['String']['input']
	rangeFilters: Array<RangeFilterInput> | RangeFilterInput
	query: Scalars['String']['input']
	sorting?: InputMaybe<Array<PostingSorting> | PostingSorting>
	plz?: InputMaybe<Scalars['String']['input']>
	distance?: InputMaybe<Scalars['Int']['input']>
}>

export type CareerListQuery = {
	__typename?: 'Query'
	postings: {
		__typename?: 'PostingsConnection'
		totalCount: number
		pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
		items: Array<{
			__typename?: 'Posting'
			title: string
			employmentType: Array<EmploymentType>
			employmentForm: Array<EmploymentForm>
			careerLevel: Array<CareerLevel>
			url: string
			startingDate?: any | null
			locations: Array<{ __typename?: 'Location'; name?: string | null }>
			source: { __typename?: 'Source'; name: string; nameShort: string; logo?: { __typename?: 'File'; url: string } | null }
		}>
		facets: Array<
			| {
					__typename: 'DiscreteFacet'
					label: string
					path: string
					options: Array<{
						__typename?: 'FilterOption'
						selected: boolean
						value: string
						resultCount: number
						disabled: boolean
					}>
			  }
			| { __typename: 'RangeFacet'; label: string; path: string; range: { __typename?: 'Range'; min: number; max: number } }
		>
	}
	closeLocations: Array<{ __typename?: 'Location'; name?: string | null }>
}

export type CareerSearchQueryVariables = Exact<{
	search: Scalars['String']['input']
}>

export type CareerSearchQuery = { __typename?: 'Query'; suggestPostings: Array<string> }

export type CareerSimilarQueryVariables = Exact<{
	first: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	discreteFilters: Array<DiscreteFilterInput> | DiscreteFilterInput
}>

export type CareerSimilarQuery = {
	__typename?: 'Query'
	similarPostings: {
		__typename?: 'PostingsConnection'
		totalCount: number
		pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
		items: Array<{
			__typename?: 'Posting'
			title: string
			employmentType: Array<EmploymentType>
			employmentForm: Array<EmploymentForm>
			careerLevel: Array<CareerLevel>
			url: string
			startingDate?: any | null
			locations: Array<{ __typename?: 'Location'; name?: string | null }>
			source: { __typename?: 'Source'; name: string; nameShort: string; logo?: { __typename?: 'File'; url: string } | null }
		}>
	}
}

export type CareerLocationsQueryVariables = Exact<{
	query: Scalars['String']['input']
	radius: Scalars['Int']['input']
}>

export type CareerLocationsQuery = {
	__typename?: 'Query'
	findLocations: Array<{ __typename?: 'Location'; uid: number; pid: number; name?: string | null; addressCity?: string | null }>
}

export type MagazinQueryVariables = Exact<{
	id: Scalars['Int']['input']
	first: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	selected: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type MagazinQuery = {
	__typename?: 'Query'
	pages: {
		__typename?: 'PagesConnection'
		totalCount: number
		items: Array<{
			__typename?: 'Page'
			uid: number
			title: string
			magazineCtaText: string
			url: string
			magazineTeaserFrame?: boolean | null
			magazineTeaserImage: {
				__typename?: 'File'
				url: string
				alternative?: string | null
				copyright?: string | null
				cropHorizontal100?: string | null
				cropHorizontal300?: string | null
				cropHorizontal500?: string | null
				cropHorizontal1000?: string | null
				cropVertical100?: string | null
				cropVertical300?: string | null
				cropVertical500?: string | null
			}
			magazineAlternativeSmallTeaserImage?: {
				__typename?: 'File'
				url: string
				alternative?: string | null
				copyright?: string | null
				image100?: string | null
				image300?: string | null
				image500?: string | null
			} | null
		}>
		pageInfo: { __typename?: 'PageInfo'; endCursor: string; hasNextPage: boolean }
		facets: Array<
			| {
					__typename: 'DiscreteFacet'
					label: string
					path: string
					options: Array<{
						__typename?: 'FilterOption'
						value: string
						disabled: boolean
						resultCount: number
						selected: boolean
					}>
			  }
			| { __typename: 'RangeFacet' }
		>
	}
}
