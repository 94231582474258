import { gql } from '@solid-primitives/graphql'

export const MAGAZINE_QUERY = gql`
	query Magazin($id: Int!, $first: Int!, $offset: Int!, $selected: [String!]!) {
		pages(
			pageIds: [$id]
			first: $first
			offset: $offset
			filters: { discreteFilters: [{ path: "categories.title", options: $selected }] }
			sorting: [{ field: sorting, order: Ascending }, { field: uid, order: Ascending }]
		) {
			items {
				uid
				title
				magazineCtaText
				url
				magazineTeaserImage {
					url
					alternative
					copyright
					cropHorizontal100: derivative(crop: "Horizontal", width: 100)
					cropHorizontal300: derivative(crop: "Horizontal", width: 300)
					cropHorizontal500: derivative(crop: "Horizontal", width: 500)
					cropHorizontal1000: derivative(crop: "Horizontal", width: 1000)
					cropVertical100: derivative(crop: "Vertical", width: 100)
					cropVertical300: derivative(crop: "Vertical", width: 300)
					cropVertical500: derivative(crop: "Vertical", width: 500)
				}
				magazineAlternativeSmallTeaserImage {
					url
					alternative
					copyright
					image100: derivative(width: 100)
					image300: derivative(width: 300)
					image500: derivative(width: 500)
				}
				magazineTeaserFrame
			}
			pageInfo {
				endCursor
				hasNextPage
			}
			facets {
				__typename
				... on DiscreteFacet {
					label
					path
					options {
						value
						disabled
						resultCount
						selected
					}
				}
			}
			totalCount
		}
	}
`
