/* @refresh reload */

import { createForm, FormStore, required, setValue } from '@modular-forms/solid'
import { debounce } from '@solid-primitives/scheduled'
import { customElement, noShadowDOM } from 'solid-element'
import { Component, createMemo, createResource, createSignal, onMount, Index, onCleanup, createEffect } from 'solid-js'
import Button from '../../shared/components/Button'
import { SearchableItem } from '@/shared/components/SearchBar'
import { TripLastSelected } from '@/shared/models/efa/efa-trips-last-selected'
import * as EfaApi from '../../shared/models/efa/efa-api'
import { createFetch, withAbort } from '@solid-primitives/fetch'
import ComboSelect from '../../shared/components/ComboSelect'
import { DatePicker } from '@ark-ui/solid'
import { Portal } from 'solid-js/web'

import FahrplanauskunftIcon from '@/assets/icons/efa/fahrplanauskunft.svg'
import FilterIcon from '@/assets/icons/efa/filter.svg'
import SwitchIcon from '@/assets/icons/efa/switch.svg'

type EfaForm = {
	// destination e.g. de:08111:6115
	destination: string
	// e.g. 23122022
	itdDateDayMonthYear: string
	itdTripDateTimeDepArr: string
	// e.g. 0812
	itdTime: string
	// e.g. de:08335:6554
	origin: string
}

type FormikString = {
	origin: string
	destination: string
	itdDateDayMonthYear?: string
	itdTime?: string
	itdTripDateTimeDepArr?: string
}

interface EfaHeaderProps {
	link: string
	headerlayout: number
}
const EfaHeader: Component<EfaHeaderProps> = props => {
	noShadowDOM()

	const debounceTime = 300

	const [inMinutes, setInMinutes] = createSignal(0)

	const [fromQuery, setFromQuery] = createSignal<string | undefined>(undefined)
	const [toQuery, setToQuery] = createSignal<string | undefined>(undefined)

	const [lastFromItems, setLastFromItems] = createSignal<SearchableItem[]>([])
	const [lastToItems, setLastToItems] = createSignal<SearchableItem[]>([])

	const [fromResource] = createFetch<EfaApi.StopFinderResponse>(fromQuery, {}, [withAbort()])
	const [toResource] = createFetch<EfaApi.StopFinderResponse>(toQuery, {}, [withAbort()])

	const [needsInfo, setNeedsInfo] = createSignal(false)
	const [infoResource] = createResource(needsInfo, EfaApi.fetchInfos)

	const [hourElement, setHourElement] = createSignal<HTMLInputElement>()
	const [minuteElement, setMinuteElement] = createSignal<HTMLInputElement>()

	const fromItems = createMemo<SearchableItem[]>(
		() =>
			fromResource()?.locations?.map(loc => {
				return { name: loc.name, id: loc.id }
			}) ?? lastFromItems()
	)

	const toItems = createMemo<SearchableItem[]>(
		() =>
			toResource()?.locations?.map(loc => {
				return { name: loc.name, id: loc.id }
			}) ?? lastToItems()
	)

	const toDebounced = debounce((query: string) => setToQuery(EfaApi.fetchStations(query)), debounceTime)
	const fromDebounced = debounce((query: string) => setFromQuery(EfaApi.fetchStations(query)), debounceTime)

	const [form, { Form, Field }] = createForm<EfaForm>({
		initialValues: {
			itdTripDateTimeDepArr: 'dep',
		},
	})

	const onFromSearch = (query: string) => {
		if (query.trim() === '') {
			return
		}
		fromDebounced(query)
	}

	const onToSearch = (query: string) => {
		if (query.trim() === '') {
			return
		}
		toDebounced(query)
	}
	const validateHours = (): boolean => {
		const hoursField = document.getElementById('hours') as HTMLInputElement
		let value = parseInt(hoursField.value, 10)

		// Check if value is a valid number and format it to two digits
		if (!isNaN(value)) {
			hoursField.value = value < 10 ? `0${value}` : value.toString()
		}

		if (!hoursField.checkValidity()) {
			hoursField.value = '23'
		}
		return true
	}

	const validateMinutes = (): boolean => {
		const minutesField = document.getElementById('minutes') as HTMLInputElement
		let value = parseInt(minutesField.value, 10)

		// Check if value is a valid number and format it to two digits
		if (!isNaN(value)) {
			minutesField.value = value < 10 ? `0${value}` : value.toString()
		}

		if (!minutesField.checkValidity()) {
			minutesField.value = '59'
		}
		return true
	}

	// Updated onSubmit function
	const onSubmit = (values: EfaForm, event: Event) => {
		event.preventDefault()
		event.stopImmediatePropagation()

		// Retrieve the current values directly from the input fields
		const hoursField = document.getElementById('hours') as HTMLInputElement
		const minutesField = document.getElementById('minutes') as HTMLInputElement
		const dateField = document.getElementById('date') as HTMLInputElement

		if (!hoursField.value || !minutesField.value || !dateField.value) {
			currentTime(0)
			currentDate()
		}

		// Validate hours field before proceeding
		if (!validateHours()) {
			return // Stop form submission if hours validation fails
		}

		// Validate hours field before proceeding
		if (!validateMinutes()) {
			return // Stop form submission if hours validation fails
		}

		const hours = hoursField ? parseInt(hoursField.value, 10) : 0
		const minutes = minutesField ? parseInt(minutesField.value, 10) : 0
		const date = dateField ? dateField.value.replaceAll('.', '') : '' // Remove dots for the required format

		// Format `itdTime` as HH:MM
		const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

		// Transform `itdTime` for submission
		const itdTime = formattedTime.replaceAll(':', '') // Convert HH:MM to HHMM

		const formik = Object.entries({
			origin: values.origin,
			destination: values.destination,
			itdDateDayMonthYear: date, // Use the value from the `date` input field
			itdTime, // Use the transformed `itdTime`
			itdTripDateTimeDepArr: values.itdTripDateTimeDepArr,
		} as FormikString)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')

		const queryString = new URLSearchParams({
			formik: encodeURI(formik),
			lng: 'de',
		}).toString()

		window.location.href = `${props.link}/trip?${queryString}`
	}

	const addMinutes = (date: Date, minutes: number) => {
		return new Date(date.getTime() + minutes * 60000)
	}

	const currentTime = (minutes: number) => {
		setInMinutes(minutes)

		const newTime = addMinutes(new Date(), minutes)
		const hours = newTime.getHours()
		const mins = newTime.getMinutes()

		const hoursField = document.getElementById('hours') as HTMLInputElement
		const minutesField = document.getElementById('minutes') as HTMLInputElement

		if (hoursField) hoursField.value = hours.toString()
		if (minutesField) minutesField.value = mins.toString()
	}

	const currentDate = () => {
		const dateField = document.getElementById('date') as HTMLInputElement

		const today = new Date()
		const day = String(today.getDate()).padStart(2, '0') // Get the day and pad with leading zero if necessary
		const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1
		const year = today.getFullYear()
		if (dateField) dateField.value = `${day}.${month}.${year}`
	}

	const stepUp = (element: HTMLInputElement, step?: number) => {
		if (step) {
			element.stepUp(step)
		} else {
			element.stepUp()
		}
		setTime()
	}

	const stepDown = (element: HTMLInputElement, step?: number) => {
		if (step) {
			element.stepDown(step)
		} else {
			element.stepDown()
		}
		setTime()
	}

	const switchValues = (event: Event, form: FormStore<EfaForm, undefined>) => {
		event.preventDefault()
		const originField = document.getElementById('origin') as HTMLInputElement
		const destinationField = document.getElementById('destination') as HTMLInputElement

		if (originField && destinationField) {
			const tempValue = originField.value
			setValue(form, 'origin', destinationField.value)
			setValue(form, 'destination', tempValue)
		}
	}

	const [isOpen, setIsOpen] = createSignal(false)
	let datePickerRef: HTMLDivElement

	const closeDatePicker = () => {
		setIsOpen(false)

		const submitButton = document.getElementById('search') as HTMLInputElement
		submitButton.focus()
	}

	const setTime = () => {
		// Retrieve and parse hours and minutes fields
		const getFieldValue = (id: string): number => {
			const field = document.getElementById(id) as HTMLInputElement
			return field ? parseInt(field.value, 10) || 0 : 0
		}

		const hours = getFieldValue('hours')
		const minutes = getFieldValue('minutes')

		// Format time as HH:MM
		const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

		console.log(formattedTime)

		const dateField = document.getElementById('date') as HTMLInputElement
		dateField.classList.add('has-time')

		// Update the time span
		const timeSpan = document.getElementById('time')
		if (timeSpan) {
			timeSpan.textContent = formattedTime + ','
		}
	}

	createEffect(() => {
		if (isOpen() && datePickerRef) {
			setTimeout(() => {
				const firstInput = document.getElementById('use-departure-time') as HTMLInputElement
				firstInput.focus()
			}, 20)
		}
	})

	const handleScroll = () => {
		if (window.scrollY > window.innerHeight && isOpen()) {
			setIsOpen(false)
		}
	}

	createEffect(() => {
		window.addEventListener('scroll', handleScroll)

		// Cleanup the event listener on component unmount
		onCleanup(() => {
			window.removeEventListener('scroll', handleScroll)
		})
	})

	onMount(() => {
		currentTime(0)

		// Load last selected values, which the efa app wrote to local storage
		const tripsLastSelected: TripLastSelected[] = JSON.parse(localStorage.getItem('tripsLastSelected') ?? '[]')

		if (tripsLastSelected.length > 0) {
			const searchableItemsFrom: SearchableItem[] = tripsLastSelected
				.map(item => {
					if (!item.origin || !item.origin.name || !item.origin.id) {
						return undefined
					}
					return { name: item.origin.name, id: item.origin.id }
				})
				.filter(item => item !== undefined) as SearchableItem[]

			// Set only unique items with unique ids
			setLastFromItems(
				searchableItemsFrom.filter((item, index) => searchableItemsFrom.findIndex(i => i.id === item.id) === index)
			)

			const searchableItemsTo: SearchableItem[] = tripsLastSelected
				.map(item => {
					if (!item.destination || !item.destination.name || !item.destination.id) {
						return undefined
					}
					return { name: item.destination.name, id: item.destination.id }
				})
				.filter(item => item !== undefined) as SearchableItem[]

			setLastToItems(
				searchableItemsTo.filter((item, index) => searchableItemsTo.findIndex(i => i.id === item.id) === index)
			)
		}
	})

	return (
		<div class="efa-fahrplanauskuft-header searchbox py-[30px]" onMouseEnter={() => setNeedsInfo(true)}>
			<div class="grid-container">
				<Form
					onSubmit={onSubmit}
					aria-label="Suchformular Fahrplanauskunft"
					class="efa-search-form js-efa-search-form flex w-full items-center gap-x-[15px]">
					<div class="wrapper-title">
						<div class="icon">
							<FahrplanauskunftIcon />
						</div>
						<h2 class="title">Fahrplanauskunft</h2>
					</div>

					<input id="language" name="language" type="hidden" value="de" />

					<div class="wrapper-origin-destination">
						<div class="efa-search-form-input efa-search-form-input--inline w-full">
							<Field name="origin" type="string" validate={[required('Bitte Haltestelle wählen')]}>
								{field => (
									<ComboSelect
										id="origin"
										value={field.value}
										items={fromItems()}
										staticLabel="Von"
										onSearch={onFromSearch}
										onSelect={item => setValue(form, field.name, item)}
										loading={fromResource.loading}
										error={field.error}
										openOnClick
									/>
								)}
							</Field>
						</div>

						<button
							class="switch-value"
							tabindex="0"
							onClick={event => switchValues(event, form)}
							aria-label="Werte vertauschen">
							<SwitchIcon />
						</button>

						<div class="efa-search-form-input efa-search-form-input--inline w-full">
							<Field name="destination" type="string" validate={[required('Bitte Haltestelle wählen')]}>
								{field => (
									<ComboSelect
										id="destination"
										value={field.value}
										items={toItems()}
										staticLabel="Nach"
										onSearch={onToSearch}
										onSelect={item => setValue(form, field.name, item)}
										loading={toResource.loading}
										error={field.error}
										openOnClick
									/>
								)}
							</Field>
						</div>
					</div>

					<div class="wrapper-date-submit">
						<DatePicker.Root
							locale="de-DE"
							closeOnSelect={false}
							class="datepicker w-full"
							onClick={() => {
								validateMinutes()
								validateHours()
							}}
							open={isOpen()}
							onOpenChange={e => setIsOpen(e.open)}
							onValueChange={setTime}>
							<DatePicker.Control>
								<DatePicker.Trigger
									class="date-time w-full"
									aria-label="Datum und Uhrzeit einstellen"
									tabindex="0">
									<DatePicker.Input
										id="date"
										class="w-full"
										placeholder="Abfahrt jetzt"
										aria-label="Datum"
										tabindex="-1"
									/>
									<span id="time" />
									<div class="icon icon-fahrplanauskuft">
										<FilterIcon />
									</div>
								</DatePicker.Trigger>
							</DatePicker.Control>

							<Portal>
								<DatePicker.Positioner>
									<DatePicker.Content class="datepicker-content bg-white" ref={el => (datePickerRef = el)}>
										<div class="wrapper">
											<div class="efa-search-form-group">
												<div
													class="form-radio-button-group flex"
													role="group"
													aria-label="Horizontale Button Gruppe">
													<span class="efa-search-form-radio-button w-full">
														<Field name="itdTripDateTimeDepArr">
															{(field, fieldProps) => (
																<input
																	{...fieldProps}
																	id="use-departure-time"
																	type="radio"
																	value="dep"
																	aria-label="ABFAHRT"
																	checked={field.value?.includes('dep')}
																	tabindex="0"
																/>
															)}
														</Field>
														<label for="use-departure-time" class="">
															ABFAHRT
														</label>
													</span>
													<span class="efa-search-form-radio-button w-full">
														<Field name="itdTripDateTimeDepArr">
															{(field, fieldProps) => (
																<input
																	{...fieldProps}
																	id="use-arrival-time"
																	type="radio"
																	value="arr"
																	aria-label="ANKUNFT"
																	checked={field.value?.includes('arr')}
																	tabindex="0"
																/>
															)}
														</Field>
														<label for="use-arrival-time" class="">
															ANKUNFT
														</label>
													</span>
												</div>
											</div>

											<DatePicker.View view="day">
												<DatePicker.Context>
													{context => (
														<>
															<DatePicker.ViewControl class="month-select flex">
																<DatePicker.PrevTrigger
																	tabindex="0"
																	class="font-bold"
																	aria-label="Zum vorherigen Monat wechseln">
																	&#60;
																</DatePicker.PrevTrigger>
																<div class="mx-auto">
																	<DatePicker.RangeText class="font-bold" />
																</div>
																<DatePicker.NextTrigger
																	tabindex="0"
																	class="font-bold"
																	aria-label="Zum nächsten Monat wechseln">
																	&#62;
																</DatePicker.NextTrigger>
															</DatePicker.ViewControl>

															<DatePicker.Table>
																<DatePicker.TableHead>
																	<DatePicker.TableRow>
																		<Index each={context().weekDays}>
																			{weekDay => (
																				<DatePicker.TableHeader>
																					{weekDay().short}
																				</DatePicker.TableHeader>
																			)}
																		</Index>
																	</DatePicker.TableRow>
																</DatePicker.TableHead>

																<DatePicker.TableBody>
																	<Index each={context().weeks}>
																		{week => (
																			<DatePicker.TableRow>
																				<Index each={week()}>
																					{day => (
																						<DatePicker.TableCell value={day()}>
																							<DatePicker.TableCellTrigger>
																								{day().day}
																							</DatePicker.TableCellTrigger>
																						</DatePicker.TableCell>
																					)}
																				</Index>
																			</DatePicker.TableRow>
																		)}
																	</Index>
																</DatePicker.TableBody>
															</DatePicker.Table>
														</>
													)}
												</DatePicker.Context>
											</DatePicker.View>

											<div class="timepicker-wrapper">
												<div class="timepicker">
													<div class="number-input">
														<div class="timepicker-control">
															<button
																tabindex="0"
																onClick={() => stepUp(hourElement()!)}
																class="plus"
																aria-label="Zahl erhöhen">
																&#60;
															</button>
															<button
																tabindex="0"
																onClick={() => stepDown(hourElement()!)}
																class="minus"
																aria-label="Zahl veringern">
																&#60;
															</button>
														</div>
														<input
															id="hours"
															class="quantity"
															aria-label="Stunden"
															min="0"
															max="23"
															name="quantity"
															value="1"
															type="number"
															onChange={() => {
																validateHours()
																setTime()
																currentDate()
															}}
															ref={setHourElement}
														/>
													</div>
													:
													<div class="number-input">
														<input
															id="minutes"
															class="quantity"
															aria-label="Minuten"
															min="0"
															max="59"
															name="quantity"
															value="1"
															type="number"
															onChange={() => {
																validateMinutes()
																setTime()
																currentDate()
															}}
															ref={setMinuteElement}
														/>
														<div class="timepicker-control">
															<button
																tabindex="0"
																onClick={() => stepUp(minuteElement()!, 10)}
																class="plus"
																aria-label="Zahl erhöhen">
																&#60;
															</button>
															<button
																tabindex="0"
																onClick={() => stepDown(minuteElement()!, 10)}
																class="minus"
																aria-label="Zahl veringern">
																&#60;
															</button>
														</div>
													</div>
												</div>

												<DatePicker.Context>
													{context => (
														<>
															<button
																tabindex="0"
																classList={{ 'is-clicked': inMinutes() === 0 }}
																aria-label="Aktuelle Uhrzeit als Abfahrts- oder Ankunftszeit auswählen"
																type="button"
																class="button button--dark now js-button-now"
																onClick={() => {
																	currentTime(0)
																	context().selectToday()
																	currentDate()
																}}>
																Jetzt
															</button>
														</>
													)}
												</DatePicker.Context>
											</div>
											<DatePicker.Context>
												{context => (
													<>
														<button
															tabindex="0"
															classList={{ 'is-clicked': inMinutes() === 0 }}
															aria-label="Eingaben übernehmen und Dropdown schließen"
															type="button"
															class="button button--dark adopt-changes"
															onClick={() => {
																setTime()
																closeDatePicker()
															}}>
															Übernehmen
														</button>
													</>
												)}
											</DatePicker.Context>
										</div>
									</DatePicker.Content>
								</DatePicker.Positioner>
							</Portal>
						</DatePicker.Root>

						<div class="efa-search-form-group">
							<Button id="search" type="submit" aria-label="Jetzt Suche starten (Seite lädt neu)" text="Suchen" />
						</div>
					</div>

					<ul class="form-errors" />
				</Form>
			</div>
		</div>
	)
}

customElement(
	'efa-header',
	{
		link: '',
		headerlayout: 1,
	},
	EfaHeader
)
